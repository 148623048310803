// Colors
$color-primary: #a8322d;
$color-primary-dark: #8f2e2b;
$color-primary-light: #9c2e2a;
$color-primary-lighter: #d85c56;

$color-light: #fff;
$color-dark: #000;
$color-lightgrey: #f1f1f1;
$color-grey: #b7b7b7;
$color-darkgrey: #7e7e7e;

$color-background: $color-light;
$color-text: $color-dark;
$color-error: red; // TODO

$content-padding-top: 1rem;
$content-padding-bottom: 2rem;

// Fonts
$font-regular:            'Roboto';
$font-bold:               'Roboto';
$font-title:              'Roboto';
$font-subtitle:           'Roboto';

$font-weight-regular:     400;
$font-weight-bold:        700;
$font-weight-title:       400;
$font-weight-subtitle:    400;

$line-height-regular:     1.7;
$line-height-title:       1.2;

$font-size-small:         0.75rem;
$font-size-regular:       1rem;
$font-size-h1:            1.5rem;
$font-size-h2:            1.25rem;
$font-size-h3:            1rem;
$font-size-h4:            1rem;

$font-margin-top-h1:          0;
$font-margin-bottom-h1:       1rem;
$font-margin-top-h2-h3-h4:    0.5rem;
$font-margin-bottom-h2-h3-h4: 1rem;

$font-margin-top-content:     0.5rem;
$font-margin-bottom-content:  0.5rem;

// Grid
$grid-columns:            12;
$grid-gutter-width:       30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
)
