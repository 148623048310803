button.new {
  position: fixed;
  right: 24px;
  bottom: 24px;
}

// TODO: Use page layout for spacing instead?
.paginator {
  // Space for the "new" button
  margin-bottom: 48px;
}

.ends-with-autocomplete {
  // 256px is the max-height set by material
  margin-bottom: 256px;
}

@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.spinner {
  animation: turn 2s infinite linear;
}

.warn-unsaved {
  margin-right: 0.5rem;
}
