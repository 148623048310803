@import "variables";

mat-select-trigger {
  mat-icon {
    position: absolute;
    margin-top: -6px;
  }

  app-content-item-icon + span {
    padding-left: 24px + 10px;
  }
}

.mat-option {
  span + app-content-item-icon {
    .mat-icon {
      margin-left: 16px;
    }
  }
}

.content-item-chip {
  .mat-icon-button {
    width: 32px;
    height: 32px;

    line-height: 18px;

    mat-icon {
      line-height: 18px;
    }

    & + .mat-chip-remove {
      margin-left: 0;
    }
  }

  mat-icon {
    height: 18px;
    font-size: 18px;
  }
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0 !important;
}

.mat-button-toggle-checked[aria-expanded="true"] {
  background-color: darken($color: $color-primary, $amount: 6) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%),
    inset 0px 1px 5px 0px rgb(0 0 0 / 12%)
      !important;
}

mat-button-toggle-group:not(.keep-defaults) {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 !important;
    line-height: 36px !important;
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

/* Material styles for toggled save button */
.save-button-container.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: none;
}

.save.mat-button-toggle {
  background-color: $color-primary;
  color: white;

  box-sizing: border-box;

  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 32px;
  line-height: 36px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;

  overflow: hidden;

  outline: none !important;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  padding: 0 16px;

  + .save {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    padding: 0 8px;
  }

  &:focus,
  &:hover {
    background-color: $color-primary !important;
  }

  &.disabled {
    background-color: $color-darkgrey !important;
    cursor: default !important;
  }
}


.import-type-field.mat-form-field {
  line-height: 1;

  .mat-form-field-wrapper {
      margin: 0;
      padding: 0;

      .mat-form-field-flex {
        padding: 0;
      }

      .mat-form-field-infix {
          padding: 0.2rem;
          border-top-width: 0.5rem;
          border-bottom: 0.15rem solid transparent;
          width: 140px;

          .mat-select-arrow-wrapper {
            transform: translateY(-5%);
          }
      }
  }
}

.dialog-no-padding {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
